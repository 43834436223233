<template>
  <div>
    <b-card no-body>
      <b-row class="px-1">
        <b-col lg="2" md="4" sm="6" class="my-1">
          <b-form-group label-size="m" :label="$i18n.t('Country')">
            <v-select
              id="countryDropdown"
              v-model="selectedCountry"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="countryDropdownOptions"
              :reduce="(option) => option.id"
            >
              <template #option="{ name }">
                <span> {{ $t(name) }}</span>
                <br />
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="4" sm="6" class="my-1">
          <b-form-group label-size="m" :label="$i18n.t('Site')">
            <v-select
              :disabled="selectedCountry == 0"
              id="siteDropdown"
              v-model="selectedSite"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(option) => option.id"
              :options="siteDropdownOptions"
            >
              <template #option="{ name }">
                <span> {{ $t(name) }}</span>
                <br />
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" sm="12" class="my-1 mr-1" style="max-width: fit-content">
          <b-form-group label-size="m" :label="$i18n.t('Workcenter')">
            <MultiSelect
              :disabled="selectedCountry == 0 || selectedSite == 0"
              style="max-width: 18rem"
              v-model="selectedWorkcenter"
              :options="workcenterDropdownOptions"
              optionLabel="name"
              placeholder="All Workcenters"
              class="workcenter-select"
              :showToggleAll="true"
            >
              <template #option="slotProps">
                {{ slotProps.option.name }}
              </template>
            </MultiSelect>
          </b-form-group>
        </b-col>
        <b-col style="max-width: fit-content" lg="2" md="5" sm="4" class="date-margin mr-1">
          <b-form-group label-for="StartDate" :label="$i18n.t('Start Date')">
            <flat-pickr style="max-width: 150px" id="StartDate" v-model="startDate" class="form-control" :config="{
              enableTime: true,
              locale: $i18n.t('locale_datePickr'),
              time_24hr: true,
              locale: $i18n.t('locale_datePickr'),
            }" :placeholder="$i18n.t('Start Date')" />
          </b-form-group>
        </b-col>
        <b-col lg="2" md="5" sm="4" style="max-width: fit-content" class="d-flex date-margin justify-content-start">
          <b-form-group label-for="endDate" :label="$i18n.t('End Date')">
            <flat-pickr id="endDate" style="max-width: 150px" v-model="endDate" class="form-control" :config="{
              enableTime: true,
              locale: $i18n.t('locale_datePickr'),
              time_24hr: true,
              locale: $i18n.t('locale_datePickr'),
            }" :placeholder="$i18n.t('End Date')" />
          </b-form-group>
        </b-col>
        <b-col lg="1" md="2" sm="4" class="d-flex justify-content-start align-items-center my-25">
          <span class="mt-1">
            <b-button variant="success" @click="GetData">{{ $t("GET") }}</b-button>
          </span>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="12" lg="6">
        <widget :key="key" :widgetData="finalProductionQuantityOptions" />
      </b-col>
      <b-col cols="12" lg="6">
        <widget :key="key" :widgetData="allWorkcentersPPMOptions" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6">
        <widget :key="key" :widgetData="finalKpiOptions" />
      </b-col>
      <b-col cols="12" lg="6">
        <widget :key="key" :widgetData="finalBreakdownRateOptions" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import MultiSelect from "primevue/multiselect";
import vSelect from "vue-select";
import useProductionReportsData from "./components/useProductionReports.js";
import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import productionReportsStoreModule from "./productionReportsStoreModule.js";
import Widget from "./components/widgets/Widget.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BFormGroup,
    vSelect,
    BButton,
    BRow,
    Widget,
    BCol,
    flatPickr,
    MultiSelect,
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-production-reports-module";

    store.dispatch("pamis/GetCountries");

    const countryDropdownOptions = computed(() => {
      const countries = store.getters["pamis/GetCountries"] ?? [];
      countries.push({ id: 0, name: "All" });
      console.log(countries);
      return countries;
    });
    const selectedCountry = ref(0);

    const siteDropdownOptions = computed(() => {
      const sites = store.getters["pamis/GetSitesByCountryId"] ?? [];
      sites.push({ id: 0, name: "All" });
      return sites;
    });
    const selectedSite = ref(0);

    const key = ref(0);
    const workcenterDropdownOptions = computed(() => {
      const workcenters = store.getters["pamis/GetWorkcentersBySiteId"] ?? [];
      console.log(workcenters);
      return workcenters;
    });
    const selectedWorkcenter = ref([]);
    const startDate = ref(new Date());
    const endDate = ref(new Date());
    watch(selectedCountry, (newVal) => {
      store.dispatch("pamis/GetSitesByCountryId", selectedCountry.value);
    });
    watch(selectedSite, (newVal) => {
      store.dispatch("pamis/GetWorkcentersBySiteId", selectedSite.value);
    });

    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, productionReportsStoreModule);
    store.dispatch("pamis-production-reports-module/GetDateFilters");

    const GetData = () => {
      const params = {
        countryId: selectedCountry.value,
        plantId: selectedSite.value,
        workcenterId: selectedWorkcenter.value.map((e) => e.id),
        startDate: startDate.value,
        endDate: endDate.value,
      };
      store.dispatch(
        "pamis-production-reports-module/GetProductionReportsData",
        params
      );
      key.value = Math.floor(Math.random() * 1000);
    };
    GetData();
    const {
      finalProductionQuantityOptions,
      allWorkcentersPPMOptions,
      finalKpiOptions,
      checkGroupStatus,
      finalBreakdownRateOptions,
    } = useProductionReportsData();

    return {
      siteDropdownOptions,
      selectedSite,
      countryDropdownOptions,
      selectedCountry,
      workcenterDropdownOptions,
      selectedWorkcenter,
      key,
      finalProductionQuantityOptions,
      finalBreakdownRateOptions,
      allWorkcentersPPMOptions,
      finalKpiOptions,
      checkGroupStatus,
      startDate,
      endDate,
      GetData,
    };
  },
};
</script>
<style scoped>
.workcenter-select {
  max-width: 12rem !important;
}

.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}

.date-margin {
  margin-top: 1.37rem;
}

.click-table:hover {
  cursor: pointer;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>