import ApiService from "@/services/pamisDashboardApi.service";
import mockdatasitegetdatefilters from "@/mockdata/sitegetdatefilters.json"
import mockdatasiteproductionquantity from "@/mockdata/productionReportsData.json"
import mockdataProductionReportsData from "@/mockdata/getProductionReportsBreakdownsData.json"
import i18n from '@/libs/i18n'

const getDefaultState = () => {
    return {
        breakdownReportsData: null,
        siteWorkcenterProductionData: null,
        dateFilterId: 2,
    };
};
const CancelAllRequests = () => {
    ApiService.abortRequests();
};
const ResetProdStates = (state) => {
    state.siteWorkcenterProductionData = null;
    state.breakdownReportsData = null;
};
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        GetDateFilters(state) {
            return state.dateFilters;
        },
        GetDateFilterId(state) {
            return state.dateFilterId;
        },
        GetProductionReportsData(state) {
            return state.siteWorkcenterProductionData;
        },
        GetBreakdownReportsData(state) {
            return state.breakdownReportsData;
        },
    },
    actions: {

        GetProductionReportsData(ctx, param) {


            let data;

            data = mockdatasiteproductionquantity.plastic;
            console.log(JSON.stringify(param))

            ctx.commit("SetSiteWorkcenterProductionData", { ...data, context: 'Production Reports Chart' });
            // return new Promise((resolve, reject) => {
            //   ApiService.query("ProductionData/Site/ProductionQuantity", {
            //     siteId: param.siteId,
            //     dateFilterId: param.dateFilterId,
            //   })
            //     .then((response) => {
            //       ctx.commit("SetSiteWorkcenterProductionData", { ...response.data, context: 'workcenters production info' });
            //       resolve(response.data);
            //     })
            //     .catch((error) => {
            //       ctx.commit("SetSiteWorkcenterProductionData", {
            //         isError: true,
            //         context: error,
            //       });
            //       reject(error);
            //     });
            // });
        },
        GetDateFilters(ctx) {
            let data = mockdatasitegetdatefilters;


            ctx.commit("SetDateFilters", data);
            // return new Promise((resolve, reject) => {
            //   ApiService.get("Utility/Site", "GetDateFilters")
            //     .then((response) => {
            //       ctx.commit("SetDateFilters", response.data);
            //       resolve(response.data);
            //     })
            //     .catch((error) => {
            //       ctx.commit("SetDateFilters", null);
            //       reject(error);
            //     });
            // });
        },
        SetDateFilterId(ctx, newId) {
            ctx.commit("SetDateFilterId", newId);
        },
        RESET_STATE(ctx) {
            ctx.commit("SET_RESET_STATE");
        },
    },
    mutations: {
        SET_RESET_STATE(state) {
            CancelAllRequests();
            Object.assign(state, getDefaultState());
        },
        SetSiteBreakdownRate(state, breakdownRate) {
            state.breakdownRate = breakdownRate;
        },
        SetSiteBreakdown(state, breakdown) {
            state.breakdown = breakdown;
        },
        SetSiteSummaryInfo(state, summaryInfo) {
            state.siteSummaryInfo = summaryInfo;
        },
        SetDateFilters(state, filters) {
            state.dateFilters = filters;
        },
        SetDateFilterId(state, newDateFilterId) {
            CancelAllRequests();
            ResetProdStates(state);
            state.dateFilterId = newDateFilterId;
        },
        SetSiteId(state, siteId) {
            state.siteId = siteId;
        },
        SetSiteWorkcenterProductionData(state, productionQuantity) {
            state.siteWorkcenterProductionData = productionQuantity;
        },
        SetBreakdownReportsData(state, breakdownReports) {
            state.breakdownReportsData = breakdownReports;
        }
    },
};
