import { computed } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import chartColorPallete from "@/assets/widget/highchartGradientColorPalettes.json";
import i18n from '@/libs/i18n'

export default function getProductionReports() {


    const finalProductionQuantityOptions = computed(() => {
        let data =
            store.getters["pamis-production-reports-module/GetProductionReportsData"];
        const dataState = GetDataStatus(data);
        if (dataState.response_status === "success") {
            let options = {
                response_status: "success",
                widgetType: "HighchartColumn",
                title: {
                    text: i18n.t("Production Quantities"),
                },
                yAxis: {
                    title: { text: i18n.t("Quantity") },
                },
                xAxis: {
                    type: "category",
                    categories: data.datasource.map((x) => x.name),
                },
                series: [
                    {
                        name: i18n.t("Total Quantity"),
                        color: chartColorPallete.blue,
                        data: data.datasource.map((x) => x.total),
                    },
                    {
                        name: i18n.t("NOK Quantity"),
                        color: chartColorPallete.red,
                        data: data.datasource.map((x) => x.nok),
                    },
                ],
            };
            return options;
        } else return dataState;
    });

    const allWorkcentersPPMOptions = computed(() => {
        let data =
            store.getters["pamis-production-reports-module/GetProductionReportsData"];
        const dataState = GetDataStatus(data);
        if (dataState.response_status === "success") {
            let options = {
                response_status: "success",
                widgetType: "HighchartColumn",
                title: {
                    text: i18n.t("Workcenters PPM"),
                },
                yAxis: {
                    title: { text: i18n.t("Million") },
                },
                xAxis: {
                    type: "category",
                    categories: data.datasource.map((x) => x.name),
                },
                series: [
                    {
                        name: "PPM",
                        color: chartColorPallete.purple,
                        data: data.datasource.map((x) => x.ppm),
                    },
                ],
            };
            return options;
        } else {
            return dataState;
        }
    });
    const finalBreakdownRateOptions = computed(() => {
        let data =
            store.getters["pamis-production-reports-module/GetProductionReportsData"];
        const dataState = GetDataStatus(data);
        if (dataState.response_status === "success") {
            let options = {
                response_status: "success",
                widgetType: "HighchartColumn",
                title: {
                    text: i18n.t("Breakdown Rate"),
                },
                yAxis: {
                    title: { text: i18n.t("Percentage") },
                },
                xAxis: {
                    type: "category",
                    categories: data.datasource.map((x) => x.name),
                },
                series: [
                    {
                        name: i18n.t("Breakdown Rate"),
                        color: chartColorPallete.olivedrab,
                        data: data.datasource.map((x) => x.breakdownRate),
                    },
                ],
            };
            return options;
        } else {
            return dataState;
        }
    });
    const finalKpiOptions = computed(() => {
        let data =
            store.getters["pamis-production-reports-module/GetProductionReportsData"];
        const dataState = GetDataStatus(data);
        if (dataState.response_status === "success") {
            let options = {
                response_status: "success",
                widgetType: "HighchartColumn",
                title: {
                    text: i18n.t("KPI"),
                },
                yAxis: {
                    title: { text: i18n.t("Percentage") },
                },
                xAxis: {
                    type: "category",
                    categories: data.datasource.map((x) => x.name),
                },
                series: [
                    {
                        name: "OEE",
                        color: chartColorPallete.green,
                        data: data.datasource.map((x) => x.oee),
                    },
                    {
                        name: i18n.t("Availability"),
                        color: chartColorPallete.orange,
                        data: data.datasource.map((x) => x.availability),
                    },
                    {
                        name: i18n.t("Performance"),
                        color: chartColorPallete.turquoise,
                        data: data.datasource.map((x) => x.performance),
                    },
                    {
                        name: i18n.t("Quality"),
                        color: chartColorPallete.yellow,
                        data: data.datasource.map((x) => x.quality),
                    },
                ],
            };

            return options;
        } else {
            return dataState;
        }
    });
    /*
    const breakdownPieOptions = computed(() => {
        const data =
            store.getters["pamis-production-reports-module/GetBreakdown"];
        const dataState = GetDataStatus(data);
        //HighchartPie
        if ((dataState.response_status == "success")) {
            for (let sourceData of data.datasource) {
                let startDate = new Date(sourceData.startDate)
                let endDate = new Date(sourceData.endDate)
                let calculatedStartDate = startDate.getTime()
                let calculatedEndDate = endDate.getTime();
                sourceData.startDate = Math.round(calculatedStartDate)
                sourceData.endDate = calculatedEndDate
            }
            const categories = getPieCategories(data.datasource);
            const pieSlices = getPieSlices(data.datasource, categories);
            const siteData = [];
            const downtimeData = [];
            for (let i = 0; i < pieSlices.length; i++) {
                siteData.push({
                    name: categories[i],
                    y: pieSlices[i].y,
                    color: pieSlices[i].color,
                });
                const drillDataLen = pieSlices[i].drilldown.data.length;
                for (let j = 0; j < drillDataLen; j += 1) {
                    const name = pieSlices[i].drilldown.categories[j];
                    let brightness = 0.1 - j / drillDataLen / 4
                    downtimeData.push({
                        name,
                        y: pieSlices[i].drilldown.data[j],
                        color: Highcharts.color(pieSlices[i].color)
                            .brighten(brightness)
                            .get(),
                    });
                }
            }
            const options = {
                response_status: "success",
                widgetType: "HighchartPie",
                tooltip: {
                    formatter: function () {
                        return '' + this.point.name + '<br/>' +
                            '</b>' + 'Downtimes: ' + ' <b>' + this.percentage.toFixed(1) + '% </b>';
                    },
                },
                title: {
                    text: i18n.t("Breakdown Type Analysis"),
                },

                series: [
                    {
                        name: "Workcenters",
                        data: siteData,
                        size: "60%",
                        dataLabels: {
                            color: "#ffffff",
                            distance: "-40%",
                        },
                    },
                    {
                        name: "Downtimes",
                        data: downtimeData,
                        size: "80%",
                        innerSize: "60%",
                        dataLabels: {
                            distance: "10%",
                            format:
                                '<b>{point.name}:</b> <span style="opacity: 0.8">{point.percentage:.1f} %</span>',

                            filter: {
                                property: "y",
                                operator: ">",
                                value: 1,
                            },
                            style: {
                                fontWeight: "normal",
                            },
                        },
                        id: "versions",
                    },
                ],
            };
            return options;
        } else {
            return dataState;
        }
    });
    function getPieCategories(datasource) {
        const categories = [];
        datasource.forEach(e => {
            if (!categories.includes(e.workcenter)) {
                categories.push(e.workcenter);
            }
        });
        return categories;
    }
    function getPieSlices(datasource, categories) {
        let c = 0;
        let colors = [
            chartColorPallete.orange,
            chartColorPallete.darkblue,
            chartColorPallete.purple,
            chartColorPallete.yellow,
            chartColorPallete.olivedrab,
            chartColorPallete.blue,
            chartColorPallete.red,
            chartColorPallete.turquoise,
        ];
        const pieSlices = [];
        for (let category of categories) {
            let categoryPieSlices = datasource.filter(
                e => e.workcenter == category
            );
            let yVal = categoryPieSlices.reduce(
                (accumulator, currentVal) =>
                    accumulator + (currentVal.endDate - currentVal.startDate),
                0
            );
            let distinctCategoryPieSlices = [];
            let distinctBreakdownCategories = [];
            categoryPieSlices.forEach(e => {
                if (!distinctBreakdownCategories.includes(e.name)) {
                    distinctBreakdownCategories.push(e.name)
                }
            })
            for (let distinctBdCategory of distinctBreakdownCategories) {
                let filteredValues = categoryPieSlices.filter(e => e.name == distinctBdCategory).map(e => (e.endDate - e.startDate));
                let accumulatedValue = filteredValues.reduce((accumulator, currVal) =>
                    accumulator + currVal, 0
                );
                let distinctPieSlice = { name: distinctBdCategory, value: accumulatedValue }
                distinctCategoryPieSlices.push(distinctPieSlice);
            }

            const color = colors[c];
            const drilldown = {
                name: category,
                categories: distinctCategoryPieSlices.map(e => e.name),
                data: distinctCategoryPieSlices.map(e => e.value)
            };
            pieSlices.push({ y: yVal, color: color, drilldown: drilldown });
            c++;
        }
        return pieSlices;
    }
        */

    const checkGroupStatus = computed(() => {
        const options = [

            finalProductionQuantityOptions.value.response_status,
            finalBreakdownRateOptions.value.response_status,
            finalKpiOptions.value.response_status,
            allWorkcentersPPMOptions.value.response_status,
        ];
        return GetGroupStatus(options);
    });

    return {
        finalProductionQuantityOptions,
        allWorkcentersPPMOptions,
        finalKpiOptions,
        finalBreakdownRateOptions,
        checkGroupStatus,
        //breakdownPieOptions,
    };
}
